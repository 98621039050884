<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>
            Formula Médica No. {{id_formula}}
            <template v-slot:button>
                <router-link :to="{ name: 'historias.perfil.usuario', params: { idUsuario: usuario.id } }"
                    class="btn btn-success btn-sm shadow-sm mx-2">
                        Regresar al perfil del paciente <i class="fas fa-history fa-fw"></i>
                </router-link>
            </template>
        </page-header-compact>
        <div class="container-xl px-4">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
             
            <div class="card mt-2" v-for="item in formula.items" :key="item.id">
                <div class="card-body" >
                    <h6>Codigo: <span class="text-danger">{{item.servicio.codigo}}</span></h6>
                    <h6>Medicamento: <span class="text-primary">{{item.servicio.descripcion}}</span></h6>
                    <h6>Forma Farmaceutica: <span class="text-primary">{{item.presentation}}</span></h6>
                    <h6>Unidad de medida: <span class="text-primary">{{item.measurement_unity}}</span></h6>
                    <h6>Via de administración: <span class="text-primary">{{item.route_of_administration}}</span></h6>
                    <h6>Dosis: <span class="text-primary">{{item.dosis}}</span></h6>
                    <h6>Cada: <span class="text-primary">{{item.frecuencia}} {{item.unidad_frecuencia}}</span></h6>
                    <h6>Duración: <span class="text-primary">{{item.duracion}} {{item.unidad_duracion}}</span></h6>
                    <h6>Total: <span class="badge badge-secondary badge-marketing">{{item.total}} {{item.presentation}}</span></h6>  
                    <h6>Indicaciones: {{ item.indications }}</h6> 
                </div>
            </div>
            <div class="form-group mt-2 text-center">
                <button @click="imprimir(formula.id)" class="btn btn-primary lift" >
                    <i class="fa fa-print fa-fw"></i> Imprimir
                </button>
            </div>
        </div>
    </main>
</template>
<script>

import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import usuarioService from '../../../../services/usuarioService';
import formulaMedicaService from '../../../../services/formulaMedicaService';
import Toast from '../../../../components/common/utilities/toast';
import historiaClinicaService from "../../../../services/historiaClinicaService";

export default {
    components: {modalInformacionUsuario,cardInformacionUsuario,PageHeaderCompact},
    data(){
        return {
            usuario: {},
            formula:{},
            id_formula: ''
        }
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarFormula(id){
            const response = await formulaMedicaService.showFormulaById(id);
            this.formula= response.data;
        },
        imprimir(id_formula){
            window.open(historiaClinicaService.formula(id_formula));
        }
    },
    async created(){
        try {
            this.LoaderSpinnerShow();
            this.id_formula=this.$route.params.id_formula;
            await this.cargarUsuario(this.$route.params.id_usuario);
            await this.cargarFormula(this.$route.params.id_formula);
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
        
    }
}
</script>